import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import $ from "jquery";
import "../index.css";
import { BsInfoCircle } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { hu } from "date-fns/locale";

function TimeStampModal(props) {
  const id = props.id;
  const setState = props.setState;
  const open = props.open;
  const setOpen = props.setOpen;

  const dateFormat = "yyyy-MM-dd";

  const [event, setEvent] = useState([]);
  const [allowTimeOfDay, setAllowTimeOfDay] = useState(false);
  const [useNow, setUseNow] = useState(false);

  function downloadData(allowTimeOfDay) {
    const URL = process.env.REACT_APP_SITE;
    $.ajax({
      url: URL + "getEvent?eventId=" + id,
      headers: { token: localStorage.getItem("token") },
      success: function (data) {
        setEvent(
          data.Media.filter((media) => media.IsVideo === 0).map((media) => {
            return {
              fileName: media.FileName,
              date: new Date(media.CreationDate + "Z"),
              thumbnailUrl: media.ThumbnailURL,
              idMedia: media.idMedia,
              date: new Date(media.CreationDate),
              format: allowTimeOfDay ? dateFormat + " HH:mm" : dateFormat,
            };
          })
        );
      },
      dataType: "json",
    });
  }

  function updateEvent(index, key, value) {
    let e = [...event];
    e[index][key] = value;
    setEvent(e);
  }

  return (
    <ReactModal
      isOpen={open}
      className={"R_Modal_Scrollable ignoreOpenEvent"}
      ariaHideApp={false}
      onRequestClose={() => {
        setState(null);
        setOpen(false);
      }}
      onAfterOpen={downloadData}
    >
      <div className="eventSetting ignoreOpenEvent">
        <label className="ignoreOpenEvent">
          �ra, perc megjelen�t�se:
          <input
            style={{ marginLeft: 10, marginRight: 20, cursor: "pointer" }}
            type={"checkbox"}
            className="ignoreOpenEvent"
            checked={allowTimeOfDay}
            onChange={(e) => {
              event.forEach((_, i) =>
                updateEvent(
                  i,
                  "format",
                  e.target.checked ? dateFormat + " HH:mm" : dateFormat
                )
              );
              setAllowTimeOfDay(e.target.checked);
            }}
            value={allowTimeOfDay}
          />
        </label>
        <label className="ignoreOpenEvent">
          Jelenlegi id� haszn�lata:
          <input
            style={{ marginLeft: 10, marginRight: 20, cursor: "pointer" }}
            type={"checkbox"}
            className="ignoreOpenEvent"
            checked={useNow}
            onChange={(e) => {
              e.target.checked
                ? event.forEach((media, i) =>
                    updateEvent(i, "date", new Date())
                  )
                : downloadData(allowTimeOfDay);
              setUseNow(e.target.checked);
            }}
            value={useNow}
          />
        </label>
        <button
          onClick={() => {
            setOpen(false);
            setState(event);
          }}
          id={`saveButton` + event.EventID}
          className="btn btn-primary ignoreOpenEvent"
        >
          Ment�s
        </button>
        <button
          className={"closeTimestampModal ignoreOpenEvent"}
          onClick={() => {
            setState(null);
            setOpen(false);
            setAllowTimeOfDay(false);
          }}
          style={{ marginLeft: "10px" }}
        >
          <CgClose size={30} color={"black"} className={"ignoreOpenEvent"} />
        </button>
      </div>
      {event.map((media, index) => {
        return (
          <div
            style={{
              flexDirection: "row",
              borderWidth: 1,
              borderColor: "red",
            }}
            className={"ignoreOpenEvent timeStampForm"}
            key={index}
          >
            <img
              key={"modalThumbnail" + index}
              style={{ height: 150, margin: "5px" }}
              alt={media.idMedia}
              src={media.thumbnailUrl}
              className={"ignoreOpenEvent"}
            />
            <label
              style={{
                flexDirection: "row",
                marginLeft: "15px",
                verticalAlign: "center",
              }}
              className={"ignoreOpenEvent"}
            >
              Id�pont:
              <label style={{ marginLeft: 10 }}>
                <DatePicker
                  selected={new Date(media.date)}
                  onSelect={(date) => {
                    updateEvent(index, "date", new Date(date.getTime()));
                  }}
                  dateFormat={dateFormat}
                  locale={hu}
                  className={"ignoreOpenEvent timestampInput"}
                  peekNextMonth={false}
                />
              </label>
              <input
                type={"time"}
                disabled={!allowTimeOfDay}
                style={{ marginLeft: "10px", verticalAlign: "center" }}
                value={
                  String(media.date.getHours()).padStart(2, "0") +
                  ":" +
                  String(media.date.getMinutes()).padStart(2, "0")
                }
                onChange={(event) => {
                  const [hours, minutes] = event.target.value.split(":");
                  const newDate = new Date(media.date);
                  newDate.setHours(hours, minutes);
                  updateEvent(index, "date", newDate);
                }}
                className={"ignoreOpenEvent timestampInput"}
              />
            </label>
          </div>
        );
      })}
    </ReactModal>
  );
}

export default TimeStampModal;
